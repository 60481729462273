<template>
  <div>
    <o-table
      class="entity-tabs-table table__overflow-auto"
      :current-page.sync="currentPage"
      :data="tableData"
      hoverable
      :mobile-cards="false"
      :paginated="tableData.length > perPage"
      pagination-simple
      :per-page="perPage"
    >
      <o-table-column
        v-slot="props"
        field="contactName"
        label="Name"
        sortable
      >
        <div class="flex align-baseline min-w-200">
          <div v-if="props.row.contactName">
            {{ props.row.contactName }}
          </div>
          <div v-else>
            –
          </div>
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="position"
        label="Position"
        sortable
      >
        <div class="flex align-baseline">
          <div v-if="props.row.position">
            {{ props.row.position }}
          </div>
          <div v-else>
            –
          </div>
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="contactLinks"
        label="Contacts"
        sortable
      >
        <div
          v-if="props.row.contactLinks && props.row.contactLinks.length"
          class="value flex"
        >
          <div
            v-for="(item, i) in props.row.contactLinks"
            :key="i"
            class="flex align-baseline mr-3"
          >
            <div
              v-if="item.link && !isValidURL(item.link) && item.key === 'Mail'"
              class="pointer"
              @click="goMailto(item.link)"
            >
              <GlImageViewer
                v-if="getValueByKey(socialIconsList, item.key, 'key')"
                class="mr-1 icon-small"
                :src="getValueByKey(socialIconsList, item.key, 'key')"
              />
            </div>
            <a
              v-else-if="item.link && isValidURL(item.link)"
              :href="item.link"
              target="_blank"
            >
              <GlImageViewer
                v-if="getValueByKey(socialIconsList, item.key, 'key')"
                class="mr-1 icon-small"
                :src="getValueByKey(socialIconsList, item.key, 'key')"
              />
              <gl-icon
                v-else
                class="mt-1"
                :height="24"
                link
                name="open"
                style="min-width: 24px"
                :width="26"
              /></a>
            <div v-else>
              {{ item.link || '–' }}
            </div>
          </div>
        </div>
        <div v-else>
          --
        </div>
      </o-table-column>

      <o-table-column
        v-if="deletable"
        v-slot="props"
        label="Actions"
        width="110"
      >
        <div class="flex small-actions">
          <gl-menu-item
            v-tooltip.top="'Delete'"
            class="ml-2"
            fullwidth
            icon="delete"
            :icon-height="24"
            :icon-width="24"
            label=""
            warn
            @click.prevent="$emit('delete', ((perPage * currentPage) - perPage) + props.index, props.row)"
          />
          <gl-menu-item
            v-tooltip.top="'Edit'"
            class="ml-2"
            fullwidth
            icon="edit-block-active"
            :icon-height="24"
            :icon-width="24"
            label=""
            warn
            @click.prevent="$emit('edit', ((perPage * currentPage) - perPage) + props.index)"
          />
        </div>
      </o-table-column>
    </o-table>
    <div 
      v-if="!noPagination"
      class="pp-pagination-table"
    >
      <div class="pp-pagination-table__left">
        <div class="pp-pagination-table__text">
          Rows per page
        </div>
        <div class="pp-pagination-table__select">
          <vSelect
            v-model="perPage"
            class="select--top-direction"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>
      </div>
      <div class="pp-pagination-table__right">
        <o-pagination
          v-if="tableData.length && totalPages > 1"
          :current.sync="currentPage"
          :per-page="perPage"
          :range-after="0"
          :range-before="0"
          :total="total"
          @change="pageChange"
        >
          <template #default="props">
            <div v-if="props.page.isCurrent" class="pp-pagination-table__pages">
              {{ `${props.page.number} of ${totalPages}` }}
            </div>
          </template>
          <template #previous="props">
            <div class="pp-pagination-table__button-prev">
              <o-pagination-button
                :page="props.page"
              >
                <gl-menu-item
                  :disabled="props.page.disabled"
                  icon="left"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
              <div class="pp-pagination-table__current">{{ props.page.number + 1 }}</div>
            </div>
          </template>
          <template #next="props">
            <div class="pp-pagination-table__button-next">
              <o-pagination-button
                :page="props.page"
              >
                <gl-menu-item
                  :disabled="props.page.disabled"
                  icon="right"
                  :icon-height="24"
                  :icon-width="24"
                  label=""
                  not-outline
                />
              </o-pagination-button>
            </div>
          </template>
        </o-pagination>
      </div>
    </div>
    <div 
      v-else 
      class="pp-pagination__none"
    />
  </div>
</template>

<script>
// Components
import GlMenuItem from '@/components/gl-menu-item.vue'
import GlIcon from '@/components/gl-icon'
import vSelect from 'vue-select'
import GlImageViewer from '@/components/gl-image-viewer'
// Utils
import { isValidURL } from '@/utils/text-formatter'
import { getValueByKey } from '@/utils/helpers'
import { goMailto } from "@/utils/go-to-route";
//models
import { socialIconsList } from '@/pages/entity/models/options-list'

export default {
  name: 'EntityContactsTable',
  components: {
    GlMenuItem,
    GlIcon,
    vSelect,
    GlImageViewer
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    noPagination: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      pagesOptions: [5, 10, 20, 50, 100],
      perPage: this.noPagination ? 9999 : 5,
      currentPage: 1,
    }
  },
  computed: {
    socialIconsList() {
      return socialIconsList
    },
    total() {
      return this.tableData.length
    },
    totalPages() {
      return Math.ceil(this.tableData.length / this.perPage)
    },
  },
  methods: {
    isValidURL,
    getValueByKey,
    goMailto,
    pageChange(event) {
      this.currentPage = event
    },
    countChange() {
      this.currentPage = 1
    },
  },
}
</script>